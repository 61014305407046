import {Box, Button} from "@mui/material"

import React, {useContext, useEffect, useState} from "react"
import Layout from "../../layouts"

import CmChooseRadio from "../../components/cfp/cmChooseRadio"
import {CmDataPicker} from "../../components/cfp/cmDataPicker"

import {GlobalStateContext} from "../../context/GlobalContextProvider";
import { navigate } from 'gatsby'

export default function DeviceKey({location}) {


        const {state = {}} = location

    const [btnState, setBtnState] = useState(false)
    const [isOpenGuestTime, setIsOpenGuestTime] = useState(false)
    const keyProp = "id"
    const labelProp = "name"
    const [itemsGroupDevice, setItemsGroupDevice] = useState([])
    const [itemsGroupMember, setItemsGroupMember] = useState([])
    const itemsKeyPermissions=[
        {name: "オーナー", id: "0"},
        {name: "マネージャー", id: "1"},
        {name: "ゲスト", id: "2"},
    ]
    const itemsGuestTime=[
        {name: "常時利用", id: "0"},
        {name: "一時利用", id: "1"},
    ]

    const {gGroupDevice, gMemberDevice,gStripe,gCompanyEmployee} = useContext(GlobalStateContext)

    useEffect(() => {
        console.log("当前分享设备列表2",state.data)
        setItemsGroupDevice(state.data)
    }, [state.data])
    useEffect(() => {

        setItemsGroupMember(gMemberDevice.list)

    }, [gMemberDevice.list])

    useEffect(() => {
        gGroupDevice.getGroupDevices()
    }, [])
    const [valueGroupDevice, setValueGroupDevice] = useState()
    const [valueGroupMember, setValueGroupMember] = useState()
    const [valueKeyPermissions, setValueKeyPermissions] = useState()
    const [valueGuestTime, setValueGuestTime] = useState()
    const [isShowTime, setIsShowTime] = useState(false)
    const [time, setTime] = useState({})
    useEffect(() => {
        let show =
            Boolean(valueGroupDevice) &&
            Boolean(valueGroupMember) &&
            Boolean(valueKeyPermissions)
        if ("2" === valueKeyPermissions) {
            show = show && Boolean(valueGuestTime)
            console.log("打印", valueGuestTime === "1")
            setIsShowTime(valueGuestTime === "1")
        }
        console.log("valueGroupDevice", valueGroupDevice)
        console.log("valueGroupMember", valueGroupMember)
        console.log("valueKeyPermissions", valueKeyPermissions)
        console.log("valueGuestTime", valueGuestTime)
        console.log("isShowTime", isShowTime)
        console.log("isShowTime", time)
        setIsOpenGuestTime("2" === valueKeyPermissions)
        setBtnState(show)
    }, [valueGroupDevice, valueGroupMember, valueKeyPermissions, valueGuestTime])
    const btnCommit = () => {
        let deviceInfo = itemsGroupDevice.find(item => item.gid === valueGroupDevice)
        let memberInfo = itemsGroupMember.find(item => item.gid === valueGroupMember)

        let  body={}
        body.keyLevel=valueKeyPermissions
        body.companyID=gStripe.customer.companyID
        body.members=memberInfo.uuids
        body.devices=deviceInfo.uuids
        body.mid=memberInfo.gid
        body.did=deviceInfo.gid

        if (valueKeyPermissions === '2') {
            if (isShowTime) {
                body.startTime = time.startTime
                body.endTime = time.endTime
            } else {
                body.startTime = ''
                body.endTime = ''
            }
        }

        gCompanyEmployee.  groupLock(body,()=>{

            navigate('/employees/group')
        })

      console.log("body", memberInfo,deviceInfo)


    }
    const callTime = (startTime, endTime) => {
        setTime({startTime: Math.floor(startTime / 1000), endTime: Math.floor(endTime / 1000)})
    }
    return (
        <>
            <Layout location={location}>
                <Box>
                    <Box
                        sx={{display: "flex", flexDirection: "column", marginTop: "8px"}}
                    >
                        <CmChooseRadio
                            items={itemsGroupDevice}
                            keyProp={'gid'}
                            labelProp={labelProp}
                            legend={"デバイスグループを選択"}
                            radioCallValue={(value) => setValueGroupDevice(value)}
                        />
                        <CmChooseRadio
                            items={itemsGroupMember}
                            keyProp={'gid'}
                            labelProp={labelProp}
                            legend={"ユーザーグループを選択"}
                            radioCallValue={(value) => setValueGroupMember(value)}
                        />
                        <CmChooseRadio
                            items={itemsKeyPermissions}
                            keyProp={keyProp}
                            labelProp={labelProp}
                            mr={"8px"}
                            legend={"グループ鍵の権限を選択"}
                            orientation={"horizontal"}
                            radioCallValue={(value) => setValueKeyPermissions(value)}
                        />
                        {isOpenGuestTime && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "8px",
                                    alignItems: "flex-end",
                                }}
                            >
                                <CmChooseRadio
                                    items={itemsGuestTime}
                                    keyProp={keyProp}
                                    labelProp={labelProp}
                                    mr={"8px"}
                                    legend={"ゲストの有効時間を設定"}
                                    orientation={"horizontal"}
                                    radioCallValue={(value) => setValueGuestTime(value)}
                                />
                                {isShowTime && (
                                    <CmDataPicker
                                        callTime={callTime}
                                        sx={{
                                            display: "flex",
                                            marginBottom: "10px",

                                            alignItems: "center",
                                            marginLeft: "40px",
                                            flexGrow: "1",
                                        }}
                                    />
                                )}
                            </Box>
                        )}

                        <Button
                            size="small"
                            variant={"contained"}
                            sx={{
                                width: "116px",
                                marginTop: "20px",
                                marginLeft: "12px",
                                padding: "4px 10px",
                                borderRadius: "6px",
                                color: "white",
                            }}
                            disabled={!btnState}
                            onClick={btnCommit}
                            disableElevation
                        >
                            グループ鍵発行
                        </Button>
                    </Box>
                </Box>
            </Layout>
        </>
    )
}
